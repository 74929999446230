@mixin styling($map) {
    .gcn-footer {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        padding: 1.5rem 0px;
        color: #fff;
        background-color: #191919;
        font-family: proxima-nova, sans-serif !important;
        display: block;
        min-height: map-get($map, 'footer-height');
        width: 100%;
    }

    .cICjmE {
        width: 100%;
        max-width: 96rem;
        margin: 0px auto;
        padding-left: map-get($map, 'cICjmE-padding-left');
        padding-right: map-get($map, 'cICjmE-padding-right');
    }

    .bFcQEn {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: map-get($map, 'bFcQEn-webkit-box-pack');
        justify-content: map-get($map, 'bFcQEn-justify-content');
    }

    .eYkWoz {
        display: flex;
        -webkit-box-align: map-get($map, 'eYkWoz-webkit-box-align');
        justify-content: space-between;
        order: map-get($map, 'eYkWoz-order');
        width: 100%;
        margin: map-get($map, 'eYkWoz-margin');
        padding: map-get($map, 'eYkWoz-padding');
        box-shadow: rgba(225, 225, 225, 0.2) 0px 1px 0px 0px;
        flex-direction: map-get($map, 'eYkWoz-flex-direction');
        align-items: map-get($map, 'eYkWoz-align-items');
    }

    .hLPafF {
        display: flex;
        margin: 0px;
        padding: 0px;
        list-style: none;
        flex-wrap: wrap;
    }

    .koxuKV {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: 0px;
        padding-right: 2rem;
        font-weight: 700;
        font-size: 0.75rem;
        text-transform: uppercase;
        margin: 0px 0px 1.25rem;

        >a {
            color: var(--white);
            text-decoration: none;
        }
    }

    .fALcmE {
        margin: map-get($map, 'fALcmE-margin');
    }

    .McnUn {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0px;
        list-style: none;
    }

    .jOkxNq {
        margin: 0px 0.3125rem;
        width: 65.9667;
        height: 21.6333;
    }

    .imGnSA {
        display: flex;
        transform: scale(1);

        >a {
            color: var(--buttonSecondaryMain);
            text-decoration: none;
        }
    }

    .jhZeWh {
        width: 100%;
        max-width: 7.625rem;
        max-height: 2.75rem;
    }

    .lcCCZf {
        width: 100%;
        max-width: 8.3125rem;
        max-height: 2.75rem;
    }

    .cskqC {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        order: map-get($map, 'cskqC-order');
        margin: 0px;
        color: var(--white);
        font-weight: normal;
        font-size: map-get($map, 'cskqC-font-size');
        font-style: normal;
        line-height: 28px;
        letter-spacing: -0.01em;
        text-align: center;
        opacity: 0.5;
    }

    small {
        font-size: smaller;
    }

    >p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .jmCvUX {
        position: relative;
        display: flex;
        -webkit-box-pack: map-get($map, 'jmCvUX-webkit-box-pack');
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: map-get($map, 'jmCvUX-justify-content');
        order: 2;
        color: var(--white);
        font-size: 0.875rem;
        text-transform: uppercase;
        background: transparent;
        border: none;
        flex: map-get($map, 'jmCvUX-flex');
        margin: map-get($map, 'jmCvUX-margin');
    }

    >button {
        border: 0px;
        background: white;
        outline: none;
        font-family: proxima-nova, sans-serif !important;
    }

    strong {
        font-weight: bold;
    }

    .lfVGje {
        order: map-get($map, 'lfVGje-order');
        margin: map-get($map, 'lfVGje-margin');
    }


    .ctZzIm {
        display: flex;
        order: map-get($map, 'lfVGje-order');
    }

    .cSJtsF {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: 0px 0.75rem;
    }

    .hRSynD {
        display: flex;
        max-height: 1.5625rem;
    }

    >a {
        color: var(--buttonSecondaryMain);
        text-decoration: none;
    }
}

@media screen and (max-width: 1024px) {
    @include styling((footer-height: 10vh,
            bFcQEn-webkit-box-pack: center,
            bFcQEn-justify-content: center,
            cICjmE-padding-left: 1.5rem,
            cICjmE-padding-right:1.5rem,
            eYkWoz-flex-direction:column-reverse,
            eYkWoz-webkit-box-align: center,
            eYkWoz-align-items: center,
            eYkWoz-order: 2,
            eYkWoz-margin:0px 0px 0.1875rem,
            eYkWoz-padding:0px,
            fALcmE-margin: 0px 0px 1.5rem,
            cskqC-order: 3,
            cskqC-font-size : smaller,
            jmCvUX-flex: 0 0 100%,
            jmCvUX-webkit-box-pack: center,
            jmCvUX-justify-content:center,
            jmCvUX-margin: 1rem,
            lfVGje-order: 1,
            lfVGje-margin: 0px 0px 1.5rem));
}


@media screen and (min-width: 1025px) {
    @include styling((footer-height: 10vh,
            bFcQEn-webkit-box-pack: justify,
            bFcQEn-justify-content: space-between,
            cICjmE-padding-left: 5.25rem,
            cICjmE-padding-right:5.25rem,
            eYkWoz-flex-direction: none,
            eYkWoz-webkit-box-align: justify,
            eYkWoz-align-items: none,
            eYkWoz-order: 1,
            eYkWoz-margin:0px 0px 1.625rem,
            eYkWoz-padding:0px 0px 1.625rem,
            fALcmE-margin:0px,
            cskqC-order: 2,
            cskqC-font-size: 12px,
            jmCvUX-flex: none,
            jmCvUX-webkit-box-pack: justify,
            jmCvUX-justify-content: space-between,
            jmCvUX-margin:none,
            lfVGje-order: 3,
            lfVGje-margin: none));
}