@mixin styling($map) {
	.discoveryplus-footer {
		position: relative;
		overflow: hidden;
		font-family: Roboto, Arial, Helvetica, sans-serif;
		background-color: #14161a;
		color: #fff;
		box-sizing: border-box;
		display: block;
		-webkit-font-smoothing: antialiased;
		min-height: map-get($map, 'footer-height');
		width: 100%;

		.discoveryplus-section-spacer {
			margin: 0;
			padding: 0;
			width: 100%;
			font-size: 1em;
			min-height: 1.5em;
		}

		.discoveryplus-styles-footerNav {
			position: relative;
			line-height: 1.4;
			overflow: hidden;
			font-size: 28.75px;
			padding-left: map-get($map, 'styles-footerNav-paddingLeft');
		}

		.discoveryplus-footer-links {
			font-size: map-get($map, 'footer-links-fontSize');
			display: map-get($map, 'footer-links-display');
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: map-get($map, 'footer-links-justifyContent');
			list-style-type: none;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			margin-block-start: map-get($map, 'footer-links-marginBlockStart');
			margin-block-end: map-get($map, 'footer-links-marginBlockEnd');
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: map-get($map, 'footer-links-paddingInlineStart');
			margin-top: map-get($map, 'footer-links-marginTop');
			margin-bottom: map-get($map, 'footer-links-marginBottom');
			margin-left: 0px;
			margin-right: map-get($map, 'footer-links-marginRight');
			padding: 0;

			>li {
				margin-top: 0.75em;
				margin-left: 1em;
				margin-right: 1em;
				margin-bottom: 0.75em;

				>a {
					opacity: .6;
					transition: opacity .2s ease-in-out;
				}
			}
		}

		.discoveryplus-styles-links {
			display: inline;
			color: inherit;
			background: none;
			transform-origin: 50% 50%;
			transform: scale(1);
			font-size: 1em;
			text-decoration: none !important;
			cursor: pointer;
		}
	}

	.scheme {
		background-color: rgb(17, 18, 22);
		box-sizing: border-box;
		color: rgb(255, 255, 255);
		display: block;
		font-family: Roboto, Arial, Helvetica, sans-serif;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		padding-top: map-get($map, 'footer-scheme-marginTop');
		padding-bottom: map-get($map, 'footer-scheme-marginBottom');
	}

	.discoveryplus-styles-footer-panel {
		position: relative;
		box-sizing: border-box;
		color: rgb(255, 255, 255);
		display: block;
		font-family: Roboto, Arial, Helvetica, sans-serif;
		font-size: map-get($map, 'styles-footer-panel-font-size');
		line-height: map-get($map, 'styles-footer-panel-line-height');
		overflow-x: hidden;
		overflow-y: hidden;
		padding-top: 0px;
		padding-right: map-get($map, 'styles-footer-panel-padding-right');
		padding-bottom: 0px;
		padding-left: map-get($map, 'styles-footer-panel-padding-left');
		-webkit-font-smoothing: antialiased;
	}

	.footer-bottom-panel-v2 {
		grid-template-areas: map-get($map, 'footer-bottom-panel-v2-grid-template-areas');
		display: grid;
		column-gap: 2em;
		row-gap: 1em;
		align-items: center;
		font-size: .8em;

		.l-social {
			grid-area: social;
			list-style-type: none;
			display: flex;
			flex-direction: row;
			padding: 0;
			margin: 0 -0.33em;
			font-size: 1.1em;

			>li {
				margin: map-get($map, 'footer-bottom-panel-v2-l-social-margin');
			}
		}

		.l-legal {
			grid-area: legal;
			font-size: map-get($map, 'footer-bottom-panel-v2-l-legal-font-size');
			text-align: center;
			color: #9ba1a9;
			flex: 1 1 100%;
			box-sizing: border-box;
			display: block;
		}

		.l-logo {
			grid-area: logo;
			justify-self: flex-end;
			display: flex;
			height: map-get($map, 'footer-bottom-panel-v2-l-logo-height');
			width: auto;
		}

		>ul {
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
		}
	}

	.discoveryplus-styles-social-links {
		width: map-get($map, 'styles-social-links-width');
		height: map-get($map, 'styles-social-links-height');
		text-decoration: none;
		text-decoration-color: transparent;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		border-width: 2px;
		color: #ffffff;
		border: 2px solid #fff;
		text-align: center;
		letter-spacing: .01em;
		transform-origin: 50% 50%;
		transform: scale(1);
		transition: text-decoration-color 200ms, background-color 200ms, color 200ms, border-color 200ms, transform 200ms, opacity ease-in 200ms;
		opacity: 1;
		font-size: 1em;
	}

	.svg-icon {
		display: inline-block;
		font-size: map-get($map, 'svg-icon-font-size');
		height: map-get($map, 'svg-icon-height');
		cursor: pointer;
		fill: rgb(255, 255, 255);
		font-family: Roboto, Arial, Helvetica, sans-serif;
		height: auto;
		letter-spacing: .01em;
		line-height: 35.42px;
		list-style-type: none;
		text-align: center;
		text-decoration-thickness: auto;
		transform-origin: 0px 0px;
	}

	.svg-icon:not(.m-image) svg {
		width: auto;
		height: map-get($map, 'svg-icon-m-image-height');
	}
}

@media screen and (max-width: 1039px) {
	@include styling((footer-height: 10vh,
			styles-footerNav-paddingLeft: 4vw,
			footer-links-fontSize: 11.7px,
			footer-links-display: grid,
			footer-links-justifyContent: flex-start,
			footer-links-marginBlockStart: 0px,
			footer-links-marginBlockEnd: 0px,
			footer-links-paddingInlineStart: 0px,
			footer-links-marginTop: 2.2em,
			footer-links-marginBottom: 0px,
			footer-links-marginRight: 0px,
			footer-links-width: 358.812px,
			footer-scheme-marginTop: 2.2em,
			footer-scheme-marginBottom: 2.2em,
			styles-footer-panel-font-size: 4vw,
			styles-footer-panel-padding-right: 4vw,
			styles-footer-panel-padding-left: 4vw,
			styles-footer-panel-line-height: 1.4,
			footer-bottom-panel-v2-l-social-margin: 0 0.33em,
			styles-social-links-width: 2.5em,
			styles-social-links-height: 2.5em,
			svg-icon-font-size: 1em,
			svg-icon-height: 1em,
			svg-icon-m-image-height: 1em,
			footer-bottom-panel-v2-l-legal-font-size: 1em,
			footer-bottom-panel-v2-grid-template-areas: "social logo"
			"legal legal",
			footer-bottom-panel-v2-l-logo-height: 1.8em));
}

@media screen and (min-width: 1039px) {
	@include styling((footer-height: 10vh,
			styles-footerNav-paddingLeft: 3vw,
			footer-links-fontSize: .55em,
			footer-links-display: flex,
			footer-links-justifyContent: center,
			footer-links-marginBlockStart: 1em,
			footer-links-marginBlockEnd: 1em,
			footer-links-paddingInlineStart: 2em,
			footer-links-marginTop: 2em,
			footer-links-marginBottom: 1em,
			footer-links-marginRight: -2em,
			footer-links-width: 100%,
			footer-scheme-marginTop: 2.2em,
			footer-scheme-marginBottom: 2.2em,
			styles-footer-panel-font-size: 1.5vw,
			styles-footer-panel-padding-right: 2.2em,
			styles-footer-panel-padding-left: 2.2em,
			styles-footer-panel-line-height: 2em,
			footer-bottom-panel-v2-l-social-margin: 0 0.5em,
			styles-social-links-width: 1.75em,
			styles-social-links-height: 1.75em,
			svg-icon-font-size: 0.6em,
			svg-icon-height: 1.5em,
			svg-icon-m-image-height: 1.6em,
			footer-bottom-panel-v2-l-legal-font-size: 0.75em,
			footer-bottom-panel-v2-grid-template-areas: "social legal legal logo",
			footer-bottom-panel-v2-l-logo-height: 1.4em));
}