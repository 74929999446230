@mixin transition($time, $type) {
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.main-panel {
    // position: relative;
    // overflow-y: auto;
    overflow-x: hidden;
    float: right;
    width: 100%; //$sidebar-width;
    background-color: #ffffff;
    ;


    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
}