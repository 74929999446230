.max-header {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .dropdown-toggle.btn.btn-primary,
  .dropdown-toggle.btn.btn-primary:focus,
  .dropdown-toggle.btn.btn-primary:active:focus,
  .dropdown-toggle.btn.btn-primary.active:focus {
    color: #fff;
    background-color: #000000;
    border-top-color: #000000;
    border-right-color: #000000;
    border-bottom-color: #000000;
    border-left-color: #fff;
    outline: none;
    box-shadow: none;
    border-radius: 0%;
  }

  .dropdown-item:active:focus {
    color: #fff;
    background-color: #000000;
    outline: none;
    // box-shadow: none;
  }
}

@media screen and (max-width: 767px) {
  #logo-max {
    content: url("./../../../logos/max.svg");
  }
}

@media screen and (min-width: 768px) {
  #logo-max {
    content: url("./../../../logos/max.svg");
  }
}

@font-face {
  font-display: swap;
  font-family: "Max Sans";
  font-style: normal;
  font-weight: 300;
  src:
    url("https://braze-images.com/appboy/communication/assets/font_assets/files/645d2129e40b0015c8e2e858/original.woff2")
      format("woff2"),
    url("https://braze-images.com/appboy/communication/assets/font_assets/files/645d21292361b943a7d1981b/original.woff")
      format("woff"),
    url("https://braze-images.com/appboy/communication/assets/font_assets/files/645d2129d1706840436774aa/original.ttf")
      format("truetype");
}

.max-font {
  font-family: "Max Sans", sans-serif;
}
