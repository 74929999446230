@mixin placeholder() {
    &::-moz-placeholder {
        @content;
    }

    // Firefox
    &:-ms-input-placeholder {
        @content;
    }

    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @content;
    }

    // Safari and Chrome
}

.navbar {
    padding-top: $navbar-padding-base;
    padding-bottom: $navbar-padding-base;
    min-height: 53px;
    margin-bottom: 20px;

    .logo {
        height: 70px;
        width: auto;

        .logo-img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
        }

        position: relative;
        padding: 7px $padding-base-horizontal;
        z-index: 4;
    }
}