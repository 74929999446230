@mixin styling($map) {
    .gcn-header {
        background-color: #ba0f15;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .gcn-logo-position {
            margin-left: map-get($map, 'logo-margin-left');
            margin-right: map-get($map, 'logo-margin-right');
        }

        .dropdown-toggle.btn.btn-primary,
        .dropdown-toggle.btn.btn-primary:focus,
        .dropdown-toggle.btn.btn-primary:active:focus,
        .dropdown-toggle.btn.btn-primary.active:focus {
            color: #fff;
            background-color: #ba0f15;
            border-top-color: #ba0f15;
            border-right-color: #ba0f15;
            border-bottom-color: #ba0f15;
            border-left-color: #fff;
            outline: none;
            box-shadow: none;
            border-radius: 0%;
        }

        .dropdown-item:active:focus {
            color: #fff;
            background-color: #ba0f15;
            outline: none;
            // box-shadow: none;
        }
    }
}


@media screen and (max-width: 767px) {
    @include styling((logo-margin-left: auto,
            logo-margin-right: auto,
        ));

    #logo-gcn {
        content: url("./../../../logos/gcn.svg");
    }
}

@media screen and (min-width: 768px) {
    @include styling((logo-margin-left: none,
            logo-margin-right: none,
        ));

    #logo-gcn {
        content: url("./../../../logos/gcn.svg");
    }
}