@mixin styling($map) {
  .body {
    position: relative;
    min-height: calc(88.3vh - #{map-get($map, 'footer-height')});
    width: map-get($map, 'body-width');
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: map-get($map, 'body-padding-top') map-get($map, 'body-padding-left') map-get($map, 'body-padding-bottom') map-get($map, 'body-padding-right');
  }

  .title {
    padding: map-get($map, 'title-padding-top') 0px map-get($map, 'title-padding-bottom') 0px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #020202;
    left: 41.94%;
    right: 41.94%;
    top: 11.18%;
    bottom: 86.51%;
  }

  .paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 17px;
    text-align: center;
    justify-content: center;
    color: #000000;
  }

  .paragraph-bold {
    font-weight: bold;
  }

  .paragraph-padding {
    padding: map-get($map, 'paragraph-padding-top') 0px map-get($map, 'paragraph-padding-bottom') 0px;
  }

  .btn-summary {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: solid 1px #939494;
    margin-bottom: 20px;
  }

  .div-summary {
    padding-top: 30px;
  }

  .error {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 17px;
    text-align: center;
    justify-content: center;
    color: #ec0606;
  }
}

@media screen and (max-width: 1000px) {
  @include styling((body-width: 100%,
      body-padding-top: 0px,
      body-padding-left: 5%,
      body-padding-bottom: 2%,
      body-padding-right: 5%,
      title-padding-top: 6%,
      title-padding-bottom: 0%,
      paragraph-padding-top: 5%,
      paragraph-padding-bottom: 6%,
      footer-height: 10vh));
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
  @include styling((body-width: 50%,
      body-padding-top: 0px,
      body-padding-left: 5%,
      body-padding-bottom: 2%,
      body-padding-right: 5%,
      title-padding-top: 6%,
      title-padding-bottom: 0%,
      paragraph-padding-top: 5%,
      paragraph-padding-bottom: 6%,
      footer-height: 10vh));
}

@media screen and (min-width: 1250px) and (max-width: 1450px) {
  @include styling((body-width: 40%,
      body-padding-top: 0px,
      body-padding-left: 5%,
      body-padding-bottom: 2%,
      body-padding-right: 5%,
      title-padding-top: 6%,
      title-padding-bottom: 0%,
      paragraph-padding-top: 5%,
      paragraph-padding-bottom: 6%,
      footer-height: 10vh));
}

@media screen and (min-width: 1450px) {
  @include styling((body-width: 33%,
      body-padding-top: 0px,
      body-padding-left: 5%,
      body-padding-bottom: 2%,
      body-padding-right: 5%,
      title-padding-top: 6%,
      title-padding-bottom: 0%,
      paragraph-padding-top: 5%,
      paragraph-padding-bottom: 6%,
      footer-height: 10vh));
}