    .gtv-header {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .dropdown-toggle.btn.btn-primary,
        .dropdown-toggle.btn.btn-primary:focus,
        .dropdown-toggle.btn.btn-primary:active:focus,
        .dropdown-toggle.btn.btn-primary.active:focus {
            color: #0e1334;
            background-color: #ffffff;
            border-top-color: #ffffff;
            border-right-color: #ffffff;
            border-bottom-color: #ffffff;
            border-left-color: #0e1334;
            outline: none;
            box-shadow: none;
            border-radius: 0%;
        }

        .dropdown-item:active:focus {
            color: #0e1334;
            background-color: #ffffff;
            outline: none;
            // box-shadow: none;
        }
    }

    @media screen and (max-width: 767px) {
        #logo-gtv {
            content: url("./../../../logos/gtv_mobile.png");
        }
    }

    @media screen and (min-width: 768px) {
        #logo-gtv {
            content: url("./../../../logos/gtv.png");
        }
    }