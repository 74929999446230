.eurosportPlayer-header {
    background-color: #0e1334;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .dropdown-toggle.btn.btn-primary,
    .dropdown-toggle.btn.btn-primary:focus,
    .dropdown-toggle.btn.btn-primary:active:focus,
    .dropdown-toggle.btn.btn-primary.active:focus {
        color: #fff;
        background-color: #0e1334;
        border-top-color: #0e1334;
        border-right-color: #0e1334;
        border-bottom-color: #0e1334;
        border-left-color: #fff;
        outline: none;
        box-shadow: none;
        border-radius: 0%;
    }

    .dropdown-item:active:focus {
        color: #fff;
        background-color: #0e1334;
        outline: none;
        // box-shadow: none;
    }
}

@media screen and (max-width: 767px) {
    #logo-eurosportPlayer {
        content: url("./../../../logos/eurosportPlayer.svg");
    }
}

@media screen and (min-width: 768px) {
    #logo-eurosportPlayer {
        content: url("./../../../logos/eurosportPlayer.svg");
    }
}