@mixin styling($map) {
	.eurosportPlayer-footer {
		position: relative;
		min-height: map-get($map, 'footer-height');
		width: 100%;
		background-color: #0e1334;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;

		.footerLinks {
			padding-top: 32px;
			min-height: 55px;
		}

		.styles-mainContainer {
			width: 94.169%;
			margin: 0 auto;
		}

		.styles-footerNav {
			display: map-get($map, 'footerNav-display');
			max-width: 100%;
			grid-template-columns: repeat(12, [col] minmax(0, calc(100% / 12)));
			grid-auto-rows: minmax(30px, auto);
			grid-auto-flow: row;
			flex-wrap: wrap;
			padding: map-get($map, 'footerNav-padding')
		}

		.styles-container {
			grid-area: container;
			grid-column-start: span map-get($map, 'container-grid-column-start');
			grid-column-end: auto;
			grid-row-start: span 1;
			grid-row-end: auto;
			padding-top: map-get($map, 'container-padding-top');
			padding-bottom: map-get($map, 'container-padding-bottom');
			width: 100%;
			display: flex;
			align-items: map-get($map, 'container-align-items');
			flex-direction: column;
			border-bottom: map-get($map, 'container-border-bottom');
		}

		.styles-header {
			color: #9798a6;
			font-weight: 600;
			letter-spacing: 1px;
			font-size: 0.8rem;
			font-family: sans-serif;
			line-height: calc(1em + 6px);
			list-style-type: none;
		}

		.styles-list {
			list-style-type: none;
			color: #f8f8f9;
			padding-inline-start: 0px;
			text-align: map-get($map, 'list-text-align');

			>li {
				padding-bottom: 9px;
			}
		}

		.styles-subheader {
			font-size: 1rem;
			font-family: sans-serif;
			line-height: calc(1em + 6px);
			font-weight: 400;
			list-style-type: none;
			display: block;
			margin-top: 0;
			margin-bottom: 0;

			>a:hover {
				text-decoration: underline;
				color: #f8f8f9;
			}
		}

		.styles-links {
			color: #f8f8f9;
		}

		.styles-copyright {
			grid-area: copyright;
			background: #0e1334;
			color: #9798a6;
			padding-top: 16px;
			padding-bottom: 24px;
			padding-left: map-get($map, 'copyright-padding-left');
			padding-right: map-get($map, 'copyright-padding-right');
			border-top: map-get($map, 'copyright-border-top');
			font-family: Inter, "Helvetica Neue", sans-serif;
			font-size: 0.8rem;
			letter-spacing: 1px;
			line-height: 24px;
			display: block;
			text-align: map-get($map, 'copyright-text-align');
		}

		.style-socialList {
			padding-left: 0;

			>li {
				display: inline-block;
				margin-right: 16px;
			}
		}

		.styles-socialIcon {
			width: 40px;
		}

		.styles-socialLink {
			cursor: pointer;
			display: inline-block;
			border-radius: 50%;
		}
	}
}

@media screen and (max-width: 1039px) {
	@include styling((footer-height: 10vh,
			footerNav-display: flex,
			container-grid-column-start: 6,
			container-border-bottom: solid 1px #41435b,
			list-text-align: center,
			copyright-padding-left: 8%,
			copyright-padding-right:8%,
			copyright-border-top: 0,
			container-padding-top: 32px,
			container-padding-bottom: 32px,
			footerNav-padding: 0 20px,
			container-align-items: center,
			copyright-text-align: center));
}

@media screen and (min-width: 1039px) {
	@include styling((footer-height: 10vh,
			footerNav-display: grid,
			container-grid-column-start: 3,
			container-border-bottom: 0,
			list-text-align: left,
			copyright-padding-left:55px,
			copyright-padding-right:0,
			copyright-border-top: solid 1px #55586d,
			container-padding-top: 0,
			container-padding-bottom: 0,
			footerNav-padding: 0,
			container-align-items: left,
			copyright-text-align: left));
}